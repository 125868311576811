/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

mat-tab-group {
  &.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-ink-bar {
    display: unset !important;
  }

  &.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
    background-color: unset !important;
  }
}

.dark {
  mat-tab-group {
    &.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
      background-color: unset !important;
    }
  }
}

hr {
  margin: 0 !important;
}

.rounded-t-2xl.shadow-inner:before {
  display: block;
  content: "";
  position: absolute;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  height: 20px;
  border: 0;
  border-radius: 1rem 1rem 0 0;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

// =================
//   Pretty Json
// =================
pre {
  font-weight: 400;

  .number-line {
    color: #adadaf;
  }

  .string {
    color: #7ca600;
  }

  .number {
    color: #0029a4;
  }

  .boolean {
    color: #0097f1;
  }

  .null {
    color: #727990;
  }

  .key {
    color: #ff7f55;
  }
}


// =====================
//    Quill overrides
// =====================

quill-editor {
  display: block;

  .ql-toolbar {
    border-radius: 1rem 1rem 0 0;
  }

  .ql-container {
    border-radius: 0 0 1rem 1rem;
  }
}

ol li {
  list-style: decimal inside;
  padding-left: 1.5rem;
}

ul li {
  list-style: disc inside;
  padding-left: 1.5rem;
}

.ql-size-huge {
  font-size: 2.5rem;
}

.ql-size-large {
  font-size: 1.5rem;
}

.ql-size-small {
  font-size: .75rem;
}

app-note-item a {
  color: var(--fuse-primary);

  &:hover {
    text-decoration: underline;
  }
}

// ===================
//   Custom Classes
// ===================
.mat-icon-button-small {

  &.mat-icon-button {
    height: 24px;
    width: 24px;
    min-height: 24px;
  }

  .mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    line-height: 16px;
  }
}
